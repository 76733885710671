import React, { useState, useEffect } from "react";
import "./Cookies.css";
import cookies_img from "../../assets/images/cookies.svg";
import close from "../../assets/icons/close.svg";
import tick from "../../assets/icons/tick.svg";
import cookie_background from "../../assets/images/cookie-mob-background.svg";
import Popup from "reactjs-popup";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";

export default function Cookies() {
  const [openCookiesPopup, setOpenCookiesPopup] = useState(false);
  const [openMobCookiesPopup, setOpenMobCookiesPopup] = useState(false);
  const [cookies, setCookie] = useCookies(["consent"]);
  const [showCookiesConsent, setShowCookiesConsent] = useState(true);

  const openPopup = () => {
    setOpenCookiesPopup(true);
  };
  const closeModal = () => {
    setOpenCookiesPopup(false);
    showMobileConsent();
  };

  const showMobileConsent = () => {
    window.innerWidth < 992
      ? setOpenMobCookiesPopup(true)
      : setOpenMobCookiesPopup(false);
  };

  const acceptCookies = () => {
    setShowCookiesConsent(false);
    setOpenCookiesPopup(false);
    setCookie("consent", true, { maxAge: 31536000, sameSite: "strict" });
    setGoogleAnalytics();
    window.location.reload();
  };

  const setGoogleAnalytics = () => {
    if (window.location.pathname === "/privacy-policy") {
      ReactGA.send({ hitType: "privacy-policy", page: "/privacy-policy" });
    } else {
      ReactGA.send({ hitType: "home", page: "/home" });
    }
  };

  const declineCookies = () => {
    setOpenCookiesPopup(false);
    setShowCookiesConsent(false);
    setCookie("consent", false, { sameSite: "strict" });
    window.location.reload();
  };

  const popupOpen = () => {
    if (openCookiesPopup) {
      if (document.getElementById("popup-2") !== null) {
        document.getElementById("popup-2").scrollTop = 0;
      }
    }
  };

  useEffect(() => {
    showMobileConsent();
    if (cookies.consent === undefined) {
      setShowCookiesConsent(true);
    } else {
      setShowCookiesConsent(false);
    }
  }, []);

  return (
    <>
      {showCookiesConsent && !openCookiesPopup && (
        <div className="cookies-div">
          <div className="large-device">
            <div className="d-flex justify-content-center"></div>
            <div className="d-flex flex-row justify-content-center">
              <div>
                <img
                  className="cookies-desktop-logo"
                  src={cookies_img}
                  alt="Cookies"
                />
              </div>
              <div className="mr-auto cookies-text">
                <b>Vi bruker informasjonskapsler </b>
                <p className="">
                  For å gi deg en god opplevelse og sørge for at alt fungerer
                  som det skal på nettsidene våre,bruker vi informasjonskapsler.
                  På den måten kan vi registrere hvordan du bruker nettsidene og
                  fortløpende forbedre dem. Informasjonen som samles inn,er
                  anonym. <br />
                  <span className="more-info" onClick={() => openPopup()}>
                    Les mer om informasjonskapsler
                  </span>
                </p>
              </div>
              <div className="mr-auto cookies-close-div ">
                <button
                  className="cookies-button cookies-close"
                  onClick={() => declineCookies()}
                >
                  <img className="close-button-icon" src={close} alt="Close" />
                  Avvis
                </button>
              </div>
              <div className="mr-auto cookies-accept-div ">
                <button
                  className="cookies-button cookies-accept"
                  onClick={() => acceptCookies()}
                >
                  <img className="tick-button-icon" src={tick} alt="Tick" />
                  Aksepter
                </button>
              </div>
            </div>
          </div>
          <div className="small-device ">
            <Popup
              open={openMobCookiesPopup}
              contentStyle={{
                width: "92%",
                height: "309px",
                padding: "28px 30px 0 30px",
                marginBottom: "10px",
                backgroundImage: `url(${cookie_background})`,
                backgroundSize: "inherit",
                backgroundRepeat: "no-repeat",
              }}
              lockScroll={true}
              closeOnDocumentClick={false}
              onClose={closeModal}
            >
              <div>
                <b className="mob-popup-header">
                  Vi bruker informasjonskapsler
                </b>
                <p className="mob-popup-content">
                  For å gi deg en god opplevelse og sørge for at alt fungerer
                  som det skal på nettsidene våre,bruker vi informasjonskapsler.
                  På den måten kan vi registrere hvordan du bruker nettsidene og
                  fortløpende forbedre dem. Informasjonen som samles inn,er
                  anonym.{" "}
                  <span className="more-info" onClick={() => openPopup()}>
                    Les mer om informasjonskapsler
                  </span>
                </p>
                <br />
              </div>
              <div className="d-flex flex-row justify-content-center">
                <div className="mr-auto cookies-close-div-2 ">
                  <button
                    className="cookies-button cookies-close"
                    onClick={() => declineCookies()}
                  >
                    <img
                      className="close-button-icon"
                      src={close}
                      alt="Close"
                    />
                    Avvis
                  </button>
                </div>
                <div className="mr-auto cookies-accept-div-2 ">
                  <button
                    className="cookies-button cookies-accept"
                    onClick={() => acceptCookies()}
                  >
                    <img className="tick-button-icon" src={tick} alt="Tick" />
                    Aksepter
                  </button>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      )}
      <div>
        <Popup
          open={openCookiesPopup}
          onOpen={popupOpen}
          contentStyle={{
            width: openMobCookiesPopup ? "90% " : "",
            padding: openMobCookiesPopup
              ? "28px 30px 50px 30px"
              : "50px 75px 20px 75px",
          }}
          overlayStyle={{ background: openMobCookiesPopup && "#373651" }}
          closeOnDocumentClick={false}
          lockScroll={true}
          onClose={closeModal}
        >
          <div className="d-flex flex-row justify-content-center pb-20">
            <img className="" src={cookies_img} alt="Cookies" />
          </div>
          <div className="popup-text">
            <b>Vi bruker informasjonskapsler (cookies)</b>
            <p>
              For å gi deg en god opplevelse og sørge for at alt fungerer som
              det skal på nettsidene våre, bruker vi informasjonskapsler. På den
              måten kan vi registrere hvordan du bruker nettsidene og
              fortløpende forbedre dem. Informasjonen som samles inn, er anonym.
            </p>
            <br />
            <b>
              Hva er informasjonskapsler (cookies), og hvordan bruker vi dem?
            </b>
            <p>
              Informasjonskapsler er små tekstfiler, som lagres på de digitale
              enhetene dine – som for eksempel mobilen eller datamaskinen din,
              når du godkjenner bruk av cookies. De registrerer blant annet
              informasjon om hvilke sider du besøker på bilista.no. Med
              informasjonskapslene kan vi også registrere hvordan du bruker
              nettsidene våre, slik at vi fortløpende kan forbedre dem og gjøre
              dem enklere å bruke.
            </p>
            <br />
            <b>Hva slags informasjon samler vi inn?</b>
            <p>
              Vi benytter Google Analytics til å hente inn informasjon. Denne
              informasjonen er anonym og kan ikke identifisere deg som
              enkeltperson. Informasjonen vi samler inn, er statistikk basert på
              målinger av aktivitet på nettsidene - som for eksempel:
            </p>
            <ul>
              <li>trafikken til nettsiden; hvor mange som besøker siden</li>
              <li>
                hva brukerne gjør på nettsiden vår; hvilke sider de besøker og
                hvor ofte
              </li>
              <li>
                brukernes omtrentlige geografiske plassering basert på
                IP-adresse (som deretter blir anonymisert)
              </li>
              <li>
                teknisk informasjon om nettleseren og operativsystemet til
                brukerne{" "}
              </li>
            </ul>
            <p>
              Vi samler også inn informasjon om innlastningshastigheten på
              nettsiden, om den krasjer eller viser feilmeldinger. Informasjonen
              som samles inn gjennom Google Analytics, lagres på Googles servere
              i USA.
            </p>
            <br />
            <b>Hvem får innsyn i informasjonen?</b>
            <p>
              Informasjonen vi samler inn, deles bare internt i GMG og med
              selskapene som lager nettstatistikk for oss.
              <br />
              <br />
              Vi sikrer at personopplysningene du gir oss ikke blir misbrukt, i
              henhold til reglene i personopplysningsloven. Det gjelder for
              eksempel informasjon om hvordan du bruker nettstedet og alle andre
              opplysninger som logges ved besøk.
              <br />
              <br />
              Informasjonskapslene våre kan ikke spre skadelige programmer eller
              skade PC-en din på noen måte.
            </p>
          </div>
          {!openMobCookiesPopup ? (
            <div className="d-flex flex-row justify-content-center p-25">
              <div className="mr-auto cookies-close-div-2 ">
                <button
                  className="cookies-button cookies-close"
                  onClick={() => declineCookies()}
                >
                  <img className="close-button-icon" src={close} alt="Close" />
                  Avvis
                </button>
              </div>
              <div className="mr-auto cookies-accept-div-2 ">
                <button
                  className="cookies-button cookies-accept"
                  onClick={() => acceptCookies()}
                >
                  <img className="tick-button-icon" src={tick} alt="Tick" />
                  Aksepter
                </button>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <div className="mr-auto mb-20 ">
                <button
                  className="cookies-button cookies-close  w-200"
                  onClick={() => declineCookies()}
                >
                  <img className="close-button-icon" src={close} alt="Close" />
                  Avvis
                </button>
              </div>
              <div className="mr-auto">
                <button
                  className="cookies-button cookies-accept w-200"
                  onClick={() => acceptCookies()}
                >
                  <img className="tick-button-icon" src={tick} alt="Tick" />
                  Aksepter
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>
    </>
  );
}

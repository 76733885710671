import React, { useEffect } from "react";
import "./Home.css";
import apple from "../../assets/icons/apple.svg";
import playstore from "../../assets/icons/playstore.svg";
import mobile from "../../assets/images/mobile_image.png";
import iphone1 from "../../assets/images/iPhone11.png";
import iphone2 from "../../assets/images/iPhone22.png";
import iphone3 from "../../assets/images/iPhone33.png";
import background from "../../assets/images/vector_background.svg";
import mobile_background from "../../assets/images/mobile_background.svg";
import Header from "../../Components/Header/Header";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Cookies from "../../Components/Cookies/Cookies";
import Footer from "../../Components/Footer/Footer";

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  const iosDownload = () => {
    ReactGA.event({
      category: "IOS Download",
      action: "IOS Download Click",
      label: "IOS Download Button",
    });
    window.open("https://apps.apple.com/no/app/bilista/id6444661493");
  };
  const androidDownload = () => {
    ReactGA.event({
      category: "Android Download",
      action: "Android Download Click",
      label: "Android Download Button",
    });
    window.open("https://play.google.com/store/apps/details?id=no.gmg.bilista");
  };
  return (
    <>
      <div
        className="d-flex home-desktop-view  background-img footer-content-gap screen-height"
        style={{
          backgroundImage: `url(${background})`,
          /* height: "84vh", */
          overflow: "hidden",
        }}
      >
        <div className="home-header">
          <Header />
        </div>

        <div className="col-5">
          <div className="main-div">
            <div className="header-text-div">
              <p className="header-text">
                Bilista,
                <br />
                enklere bilhold.
              </p>
            </div>
            <div className="content-text-div ">
              <p className="content-text">
                Velkommen til en helt ny plattform som gjør livet med bil
                enklere! Dette er verktøyet som samler alt du trenger for å ha
                oversikt, forutsigbarhet og kontroll på bilen og bilbruken din
                på ett sted.
              </p>
              <p className="content-text">
                Bilista er tilknyttet ulike leverandører, som for eksempel Flyt.
                Det betyr at du både kan administrere og betale fakturaer,
                bestille/overføre bombrikker, og beregne reisekostnader rett i
                appen. Den henter også data fra Statens vegvesen slik at
                informasjon om bilene dine er oppdatert til enhver tid.
              </p>
              <p className="content-text">
                Appen utvikles fortløpende med nye leverandører og tjenester,
                slik at du selv skal kunne velge hvilke bilholdstjenester du har
                mest nytte av.
              </p>
            </div>
            <div className="d-flex flex-column download-main-div">
              <div className="download-text-div">
                <p className="download-text">
                  Tilgjengelig nå på iOS and Android
                </p>
              </div>
              <div className="d-flex flex-row download-row-div">
                <div className="download-btn-div">
                  <button className="download-btn" onClick={iosDownload}>
                    <img
                      className="apple-button-icon"
                      src={apple}
                      alt="Download for iOS"
                    />
                    Last ned fra App Store
                  </button>
                </div>
                <div className="download-btn-div">
                  <button className="download-btn" onClick={androidDownload}>
                    <img
                      className="playstore-button-icon"
                      src={playstore}
                      alt="Download for Android"
                    />
                    Last ned fra Google Play
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-7 no-gutter phone-image-div">
          <div className="pos-relative">
            <img className="phone-img1" src={iphone1} alt="iphone1" />
            <img className="phone-img2" src={iphone2} alt="iphone2" />
            <img className="phone-img3" src={iphone3} alt="iphone3" />
          </div>
        </div>
      </div>
      <div
        className="mobile-view-display background-img"
        style={{ backgroundImage: `url(${mobile_background})` }}
      >
        <Header />
        <div className="d-flex justify-content-center">
          <div className="mob-image-div">
            <img className="mobile-img" src={mobile} alt="Mobile" />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="mob-download-text-div">
            <label>Tilgjengelig nå for iOS og Android</label>
          </div>
        </div>
        <div className="d-flex justify-content-center p-2">
          <button className="download-btn" onClick={iosDownload}>
            <img
              className="apple-button-icon"
              src={apple}
              alt="Download for iOS"
            />
            Last ned fra App Store
          </button>
        </div>
        <div className="d-flex justify-content-center p-2">
          <button className="download-btn " onClick={androidDownload}>
            <img
              className="playstore-button-icon"
              src={playstore}
              alt="Download for Android"
            />
            Last ned fra Google Play
          </button>
        </div>
        <div className="d-flex justify-content-center no-gutter">
          <p className="header-text">
            Bilista,
            <br />
            enklere bilhold.
          </p>
        </div>
        <div className="d-flex justify-content-center no-gutter">
          <p className="content-text">
            Velkommen til en helt ny plattform som gjør livet med bil enklere!
            Dette er verktøyet som samler alt du trenger for å ha oversikt,
            forutsigbarhet og kontroll på bilen og bilbruken din på ett sted.
            <br />
            <br />
            Bilista er tilknyttet ulike leverandører, som for eksempel Flyt. Det
            betyr at du både kan administrere og betale fakturaer,
            bestille/overføre bombrikker, og beregne reisekostnader rett i
            appen. Den henter også data fra Statens vegvesen slik at informasjon
            om bilene dine er oppdatert til enhver tid.
            <br />
            <br />
            Appen utvikles fortløpende med nye leverandører og tjenester, slik
            at du selv skal kunne velge hvilke bilholdstjenester du har mest
            nytte av.
          </p>
        </div>
      </div>
      <div className="cookies">
        <Cookies />
      </div>
      <Footer />
    </>
  );
};

export default Home;

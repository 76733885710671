import React from "react";

export const menuItems = [
  {
    id: 0,
    title: "Kort om Bilista-appen",
    src: (
      <div>
        <h3 className="title">
          Brukervilkår og personvern for deg som benytter deg av Bilista-appen.
        </h3>
        <h4 className="sub-title">Kort om Bilista-appen</h4>
        <p className="content">
          Bilista er utviklet og eid av Gjensidige Mobility Group AS, et selskap
          i Gjensidige-konsernet. Bilista kan lastes ned som en applikasjon til
          mobiltelefoner. Formålet med Bilista er å samle ulike tilbydere av
          bilrelaterte tjenester, slik at brukerne får en enklere, billigere og
          smartere bilhverdag.
        </p>
      </div>
    ),
  },
  {
    id: 1,
    title: "Behandlingsansvarlig",
    src: (
      <div>
        <h4 className="sub-title">Behandlingsansvarlig</h4>
        <p className="content">
          Gjensidige Mobility Group AS (GMG) er behandlingsansvarlig for
          behandlingen av personopplysninger i Bilista-appen. Hvis du har
          spørsmål til behandling av personopplysninger i Bilista, kan du
          kontakte oss på e-postadressen:
          <a
            className="mail-lab"
            href="mailto:hei@bilista.no"
            title="Send en e-post til hei@bilista.no"
            aria-label="Send en e-post til hei@bilista.no"
          >
            hei@bilista.no
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "Hva er personopplysninger",
    src: (
      <div>
        <h4 className="sub-title">Hva er personopplysninger</h4>
        <p className="content">
          Personopplysninger er opplysninger som kan knyttes direkte til deg som
          person, slik som adresse, navn, fødselsnummer, telefonnummer, e-post
          etc. Det er nødvendig for oss å bruke/behandle noen av dine
          personopplysninger for at Bilista-appen skal fungere som tiltenkt. Vi
          behandler derfor dine personopplysninger i forbindelse med at du
          bruker Bilista-appen.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    title: "Behandling av personopplysning",
    src: (
      <div>
        <h4 className="sub-title">Behandling av personopplysning</h4>
        <p className="content">
          1. Med behandling av personopplysninger menes det for eksempel
          lagring, registrering, organisering, innsamling, endring, sletting,
          overføring og bruk av personopplysninger. Kort fortalt er alt man gjør
          med personopplysninger, en behandling.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    title: "Behandlingsgrunnlag",
    src: (
      <div>
        <h4 className="sub-title">Behandlingsgrunnlag</h4>
        <p className="content">
          <p>
            For at behandlingen av personopplysninger skal være lovlig, må den
            ha et rettslig grunnlag, som kalles behandlingsgrunnlag. De
            forskjellige behandlingsgrunnlagene man kan benytte for at
            behandlingen skal være lovlig, er beskrevet i
            personopplysningsloven. Behandlingsgrunnlagene man kan benytte er:
          </p>
          <ul>
            <li>
              Nødvendig for å oppfylle avtale med den personen
              personopplysningene gjelder.
            </li>
            <li>Samtykke fra personen som personopplysningene gjelder.</li>
            <li>Nødvendig for å overholde lover og regler.</li>
            <li>
              At vi har berettiget interesse i behandlingen av
              personopplysninger og at disse interessene veier tyngre enn den
              enkeltes personvern, etter en interesseavveining.
            </li>
          </ul>
        </p>
      </div>
    ),
  },
  {
    id: 5,
    title: "Personvernansvarlig",
    src: (
      <div>
        <h4 className="sub-title">Personvernansvarlig</h4>
        <p className="content">
          <p>
            GMG har en personvernansvarlig som har i oppgave å påse at GMG og
            Bilista behandler personopplysninger i henhold til gjeldende regler.
          </p>
          <p>
            Hvis du har spørsmål om hvordan vi bruker personopplysninger om deg,
            har innspill eller har oppdaget et personvernbrudd, ta kontakt med
            vår personvernansvarlig på
            <a
              className="mail-lab"
              href="mailto:hei@bilista.no"
              title="Send en e-post til hei@bilista.no"
              aria-label="Send en e-post til hei@bilista.no"
            >
              hei@bilista.no
            </a>
            .
          </p>
        </p>
      </div>
    ),
  },
  {
    id: 6,
    title: "Hvordan behandler vi dine personopplysninger i Bilista-appen?",
    src: (
      <div>
        <h4 className="sub-title">
          Hvordan behandler vi dine personopplysninger i Bilista-appen?
        </h4>
        <p className="content">
          <p>2. Brukerkonto, identifisering og sikkerhet</p>
          <p>
            For å ta i bruk Bilista må du identifisere deg. Det gjør du med
            BankID. Når du har logget inn med BankID, forblir du logget inn selv
            om du lukker appen. Hvis du ønsker å logge ut, kan du aktivt logge
            ut i appen, eller slette appen.
          </p>
          <p>
            For at du skal kunne bruke Bilista trenger vi enkelte opplysninger
            om deg. Ved å laste ned og ta i bruk denne appen godtar du at data
            som samles inn som følge av bruk i appen, lagres i appen og hos GMG
            – og at denne informasjonen brukes til dette:
          </p>
          <ul>
            <li>
              Gjøre det enkelt å få oversikt over biler du eier eller leaser og
              informasjon om bilene.
            </li>
            <li>
              Lagre data om deg som brukes til å fylle inn skjemaer automatisk
              hvis du kjøper tjenester av tjenestetilbydere i appen.
            </li>
            <li>
              Lagre data om hvilke tjenester du benytter deg av i appen (som
              Flytpass) for å kunne tilby deg andre tjenester som kan være
              relevante for deg – for eksempel tekniske data om kjøretøyet og
              informasjon om bostedsadressen og lokasjonen din.
            </li>
          </ul>
          <p>
            GMG behandler personopplysningene for å gjennomføre avtalen med deg.
          </p>
          <p>3. Bompasseringer</p>
          <p>
            Ved å samtykke i Bilista-appen kan du hente inn informasjon fra Flyt
            om kundeforholdet ditt, inklusive bompasseringer og fakturaer. Du
            kan bruke passeringsoversikten til å beregne reisekostnader for en
            gjennomført reise.
          </p>
          <p>
            Vi lagrer ikke data fra ditt kundeforbehold hos Flyt i appen, dvs.
            at informasjon vi henter ikke lagres hos Bilista. Bilista henter
            denne dataen hver gang du trykker på Autopassavtale/Flyt-knappen.
          </p>
          <p>GMG behandler opplysningene etter ditt samtykke.</p>
          <p>4. Analyse, statistikk og kommunikasjon</p>
          <p>
            Vi har installert et analyseverktøy i appen for å kunne benytte
            brukerdata til å forbedre appen. Vi sporer anonymt om appen er i
            bruk, hvor lenge den er åpen, hva slags telefon du har, hvilke sider
            du bruker ofte, hvilke funksjonaliteter du benytter deg av, om
            sidene fungerer som de skal, hvilket operativsystem som er i bruk og
            om appen slettes.
          </p>
          <p>
            Vi benytter ikke brukerdata for å markedsføre, men når du velger
            tjenester fra leverandørene i Bilista, blir du kunde hos dem, og de
            kan derfor sende deg e-poster og SMS relatert til den kjøpte
            tjenesten.
          </p>
          <p>
            Appen lager logger over trafikken til brukerne. Disse loggene er
            anonyme og inneholder ikke personopplysninger. Det logges på en
            tilfeldig generert brukerID. Denne brukerID lagres i databasen til
            appen.
          </p>
          <p>
            Vi bruker disse loggene til feilretting i og optimalisering av
            appen. De lagres i 6 måneder før de slettes.
          </p>
          <p>
            GMG behandler personopplysningene på grunnlag av berettiget
            interesse.
          </p>
          <p>5. GMG kan sende markedsføring til deg</p>
          <p>
            Tar du i bruk appen, kan GMG sende relevant markedsføring til deg om
            egne tjenester og produkter. Denne markedsføringen kan omhandle nye
            tjenester og funksjonalitet i appen, tips og råd om bilhold, nyheter
            i appen og lignende. Du kan reservere deg mot slik markedsføring ved
            å kontakte oss på
            <a
              className="mail-lab"
              href="mailto:hei@bilista.no"
              title="Send en e-post til hei@bilista.no"
              aria-label="Send en e-post til hei@bilista.no"
            >
              hei@bilista.no
            </a>
            .
          </p>
          <p>
            Appen vil sende registreringsnumrene til Gjensidige for å undersøke
            hvilke biler som ikke er forsikret i Gjensidige. Formålet med dette
            er å tilpasse at brukeren får relevant markedsføring. Vi lagrer ikke
            denne informasjonen og den brukes ikke til andre formål. GMG
            behandler personopplysninger for markedsføringsformål på grunnlag av
            berettiget interesse.
          </p>
          <p>
            GMG behandler personopplysninger for markedsføringsformål på
            grunnlag av berettiget interesse.
          </p>
        </p>
      </div>
    ),
  },
  {
    id: 7,
    title: "Lagring av personopplysninger",
    src: (
      <div>
        <h4 className="sub-title">Lagring av personopplysninger</h4>
        <p className="content">
          <p>6. Hvilke opplysninger lagres og hvor lenge:</p>
          Disse dataene registreres og lagres:
          <ul>
            <li>Fornavn og etternavn</li>
            <li>Fødselsnummer</li>
            <li>Telefonnummer, adresse og e-post</li>
            <li>
              Registreringsnummer på bil, modell og annen informasjon om
              eide/leasede biler registrert på deg
            </li>
            <li>Lokasjon, hvis du samtykker</li>
          </ul>
          <p>
            Fødselsnummeret ditt lagres lokalt i appen på din enhet og ingen
            andre enn din app har tilgang til det. Årsaken til at det lagres er
            slik at appen kan hente inn oppdatert informasjon om hvilke kjøretøy
            bruker eier eller leaser. Informasjonen hentes fra Autosys (Statens
            vegvesen), som krever sikker innlogging/identifikasjon for å hente
            ut informasjonen på brukers vegne. Se mer informasjon om Autosys
            under avsnittet «Deling av data».
          </p>
          <p>
            Appen inneholder en tilbakemeldingsfunksjon som du kan bruke når du
            vil gi GMG tilbakemeldinger om appen. Tilbakemeldingsfunksjonen
            sender oss ditt telefonnummer og e-postadresse, slik at GMG kan
            komme i dialog med deg. Tilbakemeldingene blir sendt til en
            e-postkasse i GMG.
          </p>
        </p>
      </div>
    ),
  },
  {
    id: 8,
    title: "Deling av data",
    src: (
      <div>
        <h4 className="sub-title">Deling av data</h4>
        <p className="content">
          <p>
            Informasjon som kan knyttes til deg som person vil ikke bli delt med
            andre, med mindre vi er pålagt i lov å gjøre det eller at du
            spesifikt har samtykket til det.
          </p>
          <p>
            GMG kan dele personopplysningene dine med tjenestetilbydere i appen
            i tilfeller hvor du har bestilt tjenester hos en underleverandør og
            personopplysningene er nødvendige for å gjennomføre tjenesten. GMG
            er ikke behandlingsansvarlig for personopplysninger som behandles
            hos tjenestetilbydere i appen.
          </p>
          <p>GMG behandler personopplysningene for å gjennomføre avtale.</p>
          <p>1. Databehandlere</p>
          <p>
            Vi inngår databehandleravtaler med alle virksomheter som behandler
            personopplysninger på vegne av oss. Databehandlerne våre kan ikke
            behandle personopplysningene dine på annen måte enn det som er
            avtalt med oss, og beskrevet i denne personvernerklæringen. Vi
            bruker databehandlere blant annet som leverandører av IKT-tjenester.
          </p>
          <p>2. Andre samarbeidspartnere</p>
          <p>
            Generelt om partnere og deling av data: Når du tar i bruk ulike
            tjenester levert av andre tjenestetilbydere inne i appen, vil vi be
            deg om samtykke til deling av data mellom tjenestetilbyderen og
            Bilista.
          </p>
          <p>
            Autosys: Statens vegvesen har et register over alle biler i Norge.
            Fra dette registeret henter vi informasjon om bilene du er
            registrert som eier av, slik at vi kan vise en oversikt over bilene
            dine og knytte dem til levering av tjenester i appen.
          </p>
          <p>
            GMG henter offentlige tilgjengelige adresser fra eksterne kilder for
            å forenkle innfylling av brukerinformasjon.
          </p>
        </p>
      </div>
    ),
  },
  {
    id: 9,
    title: "Dine rettigheter",
    src: (
      <div>
        <h4 className="sub-title">Dine rettigheter</h4>
        <p className="content">
          <p> 1. Informasjon</p>
          <p>
            Vi informerer deg om hvordan vi behandler dine personopplysninger i
            denne teksten om brukervilkår og personvern. Det gis også
            informasjon i appen der det er relevant.
          </p>
          <p>2. Innsyn</p>
          <p>
            Du kan få innsyn i opplysningene Bilista har registrert om deg ved å
            sende en henvendelse på e-post til
            <a
              className="mail-lab"
              href="mailto:hei@bilista.no"
              title="Send en e-post til hei@bilista.no"
              aria-label="Send en e-post til hei@bilista.no"
            >
              hei@bilista.no
            </a>
            . I tillegg til å få vite hvilke opplysninger vi har om deg har du
            blant annet rett til å stille spørsmål om hva som er formål med
            behandlingen, om vi har utlevert opplysninger om deg og til hvem,
            hvor lenge vi lagrer opplysninger om deg, hvor vi har hentet
            opplysninger om deg og om vi tar automatiserte avgjørelser.
          </p>
          <p>3. Retting</p>
          <p>
            Dersom du mener at vi har registrert uriktige opplysninger om deg,
            kan du kreve at vi retter eller supplerer opplysningene. Du må
            sannsynliggjøre at opplysningene er uriktige og hva som er korrekt.
            Vi vil utføre retting av dine opplysninger uten ugrunnet opphold og
            normalt senest innen det har gått 30 dager. Vi oppfordrer deg til å
            ta kontakt for å rette opplysninger om deg som ikke er korrekte.
          </p>
          <p>4. Sletting / retten til å bli glemt</p>
          <p>
            I enkelte tilfeller kan du kreve at personopplysninger om deg
            slettes. Dette kalles også «retten til å bli glemt». Det finnes
            unntak fra dette, men du kan som regel kreve sletting av
            opplysningene dine dersom du trekker tilbake et samtykke, dersom det
            ikke lenger er nødvendig å beholde opplysningene dine fordi formålet
            med behandlingen er oppnådd, dersom opplysningene har blitt
            innhentet ulovlig eller dersom virksomheten har sletteplikt etter
            loven.
          </p>
          <p>
            Dersom disse tilfellene foreligger, og det ikke finnes unntak, skal
            vi gjennomføre sletting uten ugrunnet opphold og normalt innen 30
            dager. Du kan når som helst slette appen og brukerdataene som er
            lagret på mobiltelefonen din.
          </p>
          <p>
            I «Min profil» i appen kan du slette alle personopplysningene om deg
            i appens database. Alle opplysningene om deg blir slettet automatisk
            hvis du ikke har tatt i bruk appen de siste 24 månedene.
          </p>
          <p>
            Velger du å inngå et kundeforhold med noen av leverandørene i appen,
            er det til enhver tid leverandørenes vilkår som gjelder.
          </p>
          <p>5. Andre rettigheter</p>
          <p>
            Få oversikt over de andre rettighetene du har etter
            personvernregelverket når opplysninger om deg samles inn og brukes,
            på Datatilsynets nettsider:
            <a
              className="mail-lab"
              target="_blank"
              href="https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/"
            >
              Dine rettigheter | Datatilsynet
            </a>
            .
          </p>
        </p>
      </div>
    ),
  },
  {
    id: 10,
    title: "Spørsmål eller klage",
    src: (
      <div>
        <h4 className="sub-title">Spørsmål eller klage</h4>
        <p className="content">
          Har du spørsmål til eller vil klage på hvordan vi behandler dine
          personopplysninger kan du kontakte oss på
          <a
            className="mail-lab"
            href="mailto:hei@bilista.no"
            title="Send en e-post til hei@bilista.no"
            aria-label="Send en e-post til hei@bilista.no"
          >
            hei@bilista.no
          </a>
          . Kanskje finner du også svar på spørsmål inne på
          <a
            className="mail-lab"
            href="http://www.bilista.no"
            title="Besøk Bilistas nettside"
            aria-label="Besøk Bilistas nettside"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.bilista.no
          </a>
          .
        </p>
        <p className="content">
          Hvis du mener at GMG/Bilista behandler personopplysningene dine i
          strid med personvernregelverket, kan du klage til Datatilsynet. Du kan
          kontakte Datatilsynet på
          <a
            className="mail-lab"
            href="mailto:postkasse@datatilsynet.no"
            title="Send en e-post til postkasse@datatilsynet.no"
            aria-label="Send en e-post til postkasse@datatilsynet.no"
          >
            postkasse@datatilsynet.no
          </a>{" "}
          eller tlf.
          <a className="mail-lab" href="tel:+4722396900">
            22 39 69 00
          </a>
          . Vil du sende vanlig post sender du til Datatilsynet, Postboks 8177
          Dep., 0034 OSLO.
        </p>
      </div>
    ),
  },
  {
    id: 11,
    title: "Endringer i brukervilkår eller i bruk av personopplysninger",
    src: (
      <div>
        <h4 className="sub-title">
          Endringer i brukervilkår eller i bruk av personopplysninger
        </h4>
        <p className="content">
          Bilista tar forbehold om at det vil kunne skje endringer i appen og
          brukervilkårene i fremtiden. Ved mindre endringer vil vi normalt
          informere om dette direkte i appen. Gjennomfører vi vesentlige
          endringer, vil du motta varsel direkte gjennom tilgjengelige kanaler,
          for eksempel gjennom appen eller e-post.
        </p>
      </div>
    ),
  },
];

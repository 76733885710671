import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import bilista_horizontal from "../../assets/images/bilista_horizontal.svg";
import mob_footer_background from "../../assets/images/mob_footer_background.svg";
import "./Footer.css";

export default function Footer() {
  const [isHomePage, setIsHomePage] = useState(true);
  const [isPolicyPage, setIsPolicyPage] = useState(false);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["consent"]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    setNavigationTab();
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    setNavigationTab();
  };

  const setNavigationTab = () => {
    const url = window.location.pathname;
    if (url === "/privacy-policy") {
      setIsPolicyPage(true);
      setIsHomePage(false);
    } else {
      setIsPolicyPage(false);
      setIsHomePage(true);
    }
  };

  const policyPage = () => {
    if (cookies.consent) {
      ReactGA.send({ hitType: "privacy-policy", page: "/privacy-policy" });
    }
    navigate("/privacy-policy");
    setIsPolicyPage(true);
    setIsHomePage(false);
  };

  const homePage = () => {
    if (cookies.consent) {
      ReactGA.send({ hitType: "home", page: "/home" });
    }
    navigate("/");
    setIsHomePage(true);
    setIsPolicyPage(false);
  };

  return (
    <footer>
      <div className="large-device">
        <div className="d-flex justify-content-center">
          <img
            className="d-flex justify-content-center bilista-desktop-logo"
            src={bilista_horizontal}
            alt="Bilista Logo"
          />
        </div>
        <div className="d-flex flex-row justify-content-center">
          <div className="mr-auto contact-email-div">
            <p className="contact-label nav-buttons">
              <b>Kontakt oss: </b>{" "}
              <a
                className="email-label "
                href="https://flytpass.no/om-flyt/kontaktskjema"
                target="_blank"
              >
                Skriv til oss her | Flytpass.no
              </a>
            </p>
          </div>
          <div className="mr-auto p-2">
            <button
              className={
                isHomePage ? "nav-buttons selected-page" : "nav-buttons"
              }
              onClick={homePage}
            >
              Hjem
            </button>
          </div>
          <div className="mr-auto p-2">
            <button
              className={
                isPolicyPage ? "nav-buttons selected-page" : "nav-buttons"
              }
              onClick={policyPage}
            >
              Personvernerklæring
            </button>
          </div>
          <div className="mr-auto contact-number-div">
            <p className="contact-label nav-buttons">
              <b> Organisasjonsnummer: </b>
              <span className="contact-number">927 075 385</span>
            </p>
          </div>
        </div>
      </div>
      <div
        className="small-device footer-background-img pb-60"
        style={{ backgroundImage: `url(${mob_footer_background})` }}
      >
        <div className="d-flex justify-content-center bilista-logo-div">
          <img src={bilista_horizontal} alt="Bilista Logo" />
        </div>

        <div className="d-flex justify-content-center pt-2">
          <div>
            <p className="contact-label nav-buttons">
              <b>Kontakt oss: </b>{" "}
              <a
                className="email-label"
                href="https://flytpass.no/om-flyt/kontaktskjema"
                target="_blank"
              >
                Skriv til oss her | Flytpass.no
              </a>
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="mr-auto p-1">
            <button
              className={
                isHomePage ? "nav-buttons selected-page" : "nav-buttons"
              }
              onClick={homePage}
            >
              Hjem
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="mr-auto p-1">
            <button
              className={
                isPolicyPage ? "nav-buttons selected-page" : "nav-buttons"
              }
              onClick={policyPage}
            >
              Personvernerklæring
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-2">
          <div>
            <p className="contact-label nav-buttons">
              <b>Organisasjonsnummer: </b>
            </p>
            <p className="contact-label">
              <span className="contact-number">927 075 385</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

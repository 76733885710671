import React from "react";
import bilista_vertical from "../../assets/images/bilista_logo_vertical.svg";
import bilista_horizontal from "../../assets/images/bilista_horizontal.svg";
import "./Header.css";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const homePage = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <header>
      <div className="col-12 header-desktop-view logo-div" onClick={homePage}>
        <img src={bilista_horizontal} alt="Bilista Logo" />
      </div>
      <div
        className="d-flex justify-content-center pt-5 mobile-view"
        onClick={homePage}
      >
        <img src={bilista_vertical} alt="Bilista Logo" />
      </div>
    </header>
  );
}

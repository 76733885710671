import React, { useEffect } from "react";
import "./App.css";
import Home from "./Screens/Home/Home";
import Policy from "./Screens/Policy/Policy";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    if (window.location.origin.includes("bilista.no")) {
      ReactGA.initialize("G-RT1RZP97QN");
    } else if (window.location.origin.includes("dev")) {
      ReactGA.initialize("G-GZBJQ7GE1S");
    }
  }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route
            path="/policy-page"
            element={<Navigate to="/PolicyPage.html" />}
            render={() => {
              window.location.href = "/PolicyPage.html";
            }}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

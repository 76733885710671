import React, { createRef, useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { useScrollDirection } from "use-scroll-direction";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { menuItems } from "../../assets/dataConfig";
import downarrow from "../../assets/icons/vector-5.png";
import uparrow from "../../assets/icons/vector.png";
import "./Menu.css";

const Menu = (props) => {
  const [icon, setIcon] = useState(downarrow);
  const [flag, setFlag] = useState(false);
  const [index, setIndex] = useState(0);
  const [showHeader, setShowHeader] = useState(true);
  const [scrollIndex, setScrollIndex] = useState(index);
  const [elRefs, setElRefs] = useState([]);
  const dataCardRef = useRef();
  const arrLength = menuItems.length;
  const [enableVisibilitySensor, setEnableVisibilitySensor] = useState(true);
  const { isScrolling, isScrollingUp, isScrollingDown } = useScrollDirection({
    ref: dataCardRef,
  });

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [arrLength]);

  useEffect(() => {
    if (index <= 0) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  }, [index]);

  useEffect(() => {
    if (!isScrolling) {
      setEnableVisibilitySensor(true);
    }
  }, [isScrolling]);

  const onChangeContentDiv = (isVisible) => {
    if ((!isVisible && isScrollingDown) || (isVisible && isScrollingUp)) {
      if (isScrollingDown) {
        const i = scrollIndex + 1;
        setScrollIndex(i);
        setIndex(i);
      } else if (isScrollingUp) {
        const i = scrollIndex - 1;
        setScrollIndex(i);
        setIndex(i);
      }
    }
  };

  const menuSwitch = (id) => {
    setIndex(id);
  };

  const onContentScroll = (event) => {
    // for hiding header when scrolled in mobile view.
    // Commented because it is not working properly in ios
    /*  const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (scrollTop > 0 && document.getElementById("mobMainDiv")) {
      const element = document.getElementById("mobMainDiv").offsetTop;
      window.scroll(0, element);
    } */
    //  / console.log(scrollDirection);
    if (event.target.scrollTop === 0 || scrollIndex <= 0) {
      setScrollIndex(0);
      setIndex(0);
      // setShowHeader(true);
    } else {
      //  setShowHeader(false);
    }
    const isBottomOfScroll =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (isBottomOfScroll || scrollIndex >= arrLength - 1) {
      setScrollIndex(arrLength - 1);
      setIndex(arrLength - 1);
    }
  };

  const handleClick = () => {
    if (flag) {
      setIcon(downarrow);
      setFlag(false);
    } else {
      setIcon(uparrow);
      setFlag(true);
    }
  };

  const drop_Click = (id, i) => {
    setEnableVisibilitySensor(false);
    elRefs[i].current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
    setIndex(id);
    setScrollIndex(id);
    setIcon(downarrow);
    setFlag(false);
  };
  const menuClick = (id) => {
    const elmnt = document.getElementById(id).offsetTop;
    window.scroll(0, elmnt - 100);
    setIndex(id);
  };

  return (
    <>
      <div className=" desktop-view row">
        <div className="policy-header" id="myHeader">
          <Header />
        </div>
        <div className="col-4">
          <div className="sub-card">
            {menuItems.map(function(menu) {
              if (index === menu.id)
                if (index === 10 || index === 13)
                  return (
                    <div className="pad">
                      <div
                        className="
                      active-border"
                      >
                        <h3
                          className="
                       menu-active"
                          key={menu.id}
                          onClick={() => menuClick(menu.id)}
                        >
                          {menu.title}
                        </h3>
                      </div>
                    </div>
                  );
                else
                  return (
                    <div className="pad">
                      <div
                        className="
                      active-border"
                      >
                        <h3
                          className="
                       menu-active mar-top"
                          key={menu.id}
                          onClick={() => menuClick(menu.id)}
                        >
                          {menu.title}
                        </h3>
                      </div>
                    </div>
                  );
              else
                return (
                  <h3
                    className="menu-title"
                    key={menu.id}
                    onClick={() => menuClick(menu.id)}
                  >
                    {menu.title}
                  </h3>
                );
            })}
          </div>
        </div>

        <div className="col-6 pad-top">
          {menuItems.map(function(content) {
            return (
              <div className="terms" onClick={() => menuSwitch(content.id)}>
                <div className="content-card" id={content.id}>
                  {content.src}
                </div>
              </div>
            );
          })}
        </div>
        <Footer />
      </div>

      <div className="mobile-view row ">
        {showHeader && (
          <div className="policy-header">
            <Header />
          </div>
        )}

        {menuItems.map(function(menu, i) {
          if (index === menu.id)
            return (
              <div
                id="mobMainDiv"
                onClick={handleClick}
                className="drop"
                key={menu.id}
              >
                <div>
                  <div className="menu-drop">
                    <h3 className="drop-title"> {menu.title}</h3>
                    <img className="icon" src={icon} alt="icon" />
                  </div>
                </div>
              </div>
            );
        })}
        {flag && (
          <div className="dropdown">
            {menuItems.map(function(menu, i) {
              return (
                <div className="menu-drop">
                  <h4
                    className="menu-sub-title"
                    onClick={() => drop_Click(menu.id, i)}
                  >
                    {menu.title}
                  </h4>
                </div>
              );
            })}
          </div>
        )}
        <div
          ref={dataCardRef}
          className={
            showHeader ? "data-card" : "data-card data-card-height-80vh"
          }
          onScroll={onContentScroll}
        >
          {menuItems.map(function(content, i) {
            return (
              <div
                className="content-card row"
                ref={elRefs[i]}
                id={content.id}
                onClick={() => menuSwitch(content.id)}
              >
                <VisibilitySensor
                  active={enableVisibilitySensor}
                  onChange={onChangeContentDiv}
                  scrollCheck={true}
                >
                  <div className="inner-card">
                    <p className="content">{content.src}</p>
                  </div>
                </VisibilitySensor>
              </div>
            );
          })}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Menu;

import React, { useEffect } from "react";
import "./Policy.css";
import Menu from "../../Components/Menu/Menu";
import { useLocation } from "react-router-dom";

const Policy = () => {
  const location = useLocation();

  useEffect(() => {
    window.innerWidth < 992
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "scroll");
  }, []);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <>
      <div className="main-card">
        <Menu />
      </div>
    </>
  );
};

export default Policy;
